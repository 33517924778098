export default class Oferta {
    constructor() {
        this.valor_de = null;
        this.valor_ate = null;
        this.desconto = null;
        this.desconto_porcentagem = 0;
        this.cartao = false;
        this.juros_cartao = null;
        this.parcela_cartao = 0;
        this.boleto = false;
        this.juros_boleto = 0;
        this.parcela_boleto = 1;
        this.status = true;
        this.apresentante = null;
        this.data_inicio = null;
        this.data_fim = null;
        this.status_envio_sms = false;
        this.mensagem = null;
        this.protestado_entidade_uuid = null;
        this.filtro_dias_vcto_de = null;
        this.filtro_dias_vcto_ate = null;
        this.filtro_dias_upload_de = null;
        this.filtro_dias_upload_ate = null;
    }
}
