<template>
    <div>
        <!-- Cabeçalho -->
        <div align="center" justify="center" class="ma-3">
            <v-card align="left" justify="left" class="">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text pa-2">
                        fa-sharp fa-solid fa-percent </v-icon
                    >Renegociação > Gerenciar Ofertas para um Título
                </v-card-text>
            </v-card>
            <v-card
                class="my-3 pa-3"
                color="primary"
                style="border-radius: 20px;"
            >
                <v-row>
                    <v-col cols="3" xs="3" md="3" class="ma-3 pa-3">
                        <v-icon
                            class="white--text ma-6 pa-6 mx-auto my-auto"
                            size="100"
                            elevation-8
                        >
                            fa-sharp fa-solid fa-percent
                        </v-icon>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="5"
                        class="ma-3 pa-3 mx-auto my-auto"
                        align="left"
                        justify="left"
                    >
                        <v-card-text class="white--text display-1">
                            Gerenciar Ofertas <strong>Título</strong>
                            <v-chip
                                class="ma-2"
                                color="secondary"
                                label
                                text-color="white"
                            >
                                <v-icon left>
                                    mdi-label
                                </v-icon>
                                Novidade!
                            </v-chip>
                        </v-card-text>
                    </v-col>
                    <v-col
                        sm="12"
                        md="2"
                        class="ma-3 pa-3 mx-auto my-auto"
                        align="left"
                        justify="left"
                    >
                        <v-card-text
                            class="
                                white--text
                                font-weight-light
                                subtitle
                                mb-3
                                pa-3
                            "
                        >
                            Crie e gerencie ofertas para títulos, podendo criar
                            com base em valores, porcentagem de desconto e
                            valores.
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-card class=" ma-6" elevation-0 outlined>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="grey lighten-4">
                                <v-card-text
                                    class="font-weight-light black--text"
                                >
                                    <v-icon class="primary--text lighten-2">
                                        search
                                    </v-icon>
                                    Filtrar Resultados
                                </v-card-text>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="my-4">
                                    <v-col
                                        cols="9"
                                        sm="12"
                                        md="12"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-text-field
                                            solo
                                            label="Id da Oferta"
                                            hint="Informe o id único da oferta"
                                            placeholder="0001"
                                            v-model="filtros.id_oferta"
                                        ></v-text-field>
                                    </v-col>
                                    <v-tooltip right>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2
"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="my-auto"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span
                                            >Cada oferta possui um número único
                                            chamado de "id". Informe o id da
                                            oferta que deseja encontrar.</span
                                        >
                                    </v-tooltip>
                                    <v-col
                                        cols="9"
                                        xs="12"
                                        sm="12"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-text-field
                                            solo
                                            label="Número do Título"
                                            hint="Informe o número do título"
                                            placeholder="33344401"
                                            v-model="filtros.numero_titulo"
                                        ></v-text-field>
                                    </v-col>
                                    <v-tooltip right>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span
                                            >Informe o número do Título na
                                            Recuperi</span
                                        >
                                    </v-tooltip>

                                    <v-col
                                        cols="6"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                        class="mx-2 d-flex justify-center align-center"
                                    >
                                        <v-checkbox
                                            class="mt-n1 d-flex justify-center"
                                            v-model="filtros.status"
                                            label="Somente Ofetas ativas"
                                            item-text="nm_status"
                                            item-value="status"
                                            :return-object="true"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                        class="mx-2"
                                    >
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-card>
            <v-card @keyup.native.enter="aplicarFiltros" class="pa-3">
                <v-row class="my-1">
                    <v-col cols="4" xs="2" sm="2" md="7" lg="7" class="mx-3">
                    </v-col>
                    <v-col
                        cols="11"
                        xs="6"
                        sm="6"
                        md="2"
                        lg="2"
                        align="center"
                        class="mx-2"
                    >
                    </v-col>
                    <v-col
                        cols="11"
                        xs="6"
                        sm="6"
                        md="2"
                        lg="2"
                        align="center"
                        class="mx-2"
                    >
                        <v-btn
                            width="180"
                            class="mt-n1 d-flex justify-center"
                            color="primary"
                            :loading="loadingFiltros"
                            @click="botaoFiltrar"
                        >
                            Buscar
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemAviso"
                @fecharSnackbar="snackbar = false"
            />
            <v-data-table
                :headers="headers"
                :items="ofertas"
                item-key="ofertas"
                class="elevation-1 pa-3 mt-3"
            >
                <template v-slot:[`item.id`]="{ item }">
                    <span>
                        {{ item.id }}
                    </span>
                </template>
                <template v-slot:[`item.nosso_numero`]="{ item }">
                    <span class="d-flex">
                        <span class="d-flex justify-center align-center">
                            {{ item.nosso_numero }}
                        </span>
                        <v-btn
                            x-small
                            elevation="0"
                            class="ma-3"
                            fab
                            @click="exibirDetalhe(item)"
                            ><v-icon>mdi-open-in-new</v-icon></v-btn
                        >
                    </span>
                </template>
                <template v-slot:[`item.valor_de`]="{ item }">
                    <span>
                        {{ formatDinheiroString(item.valor_de) }}
                    </span>
                </template>
                <template v-slot:[`item.valor_ate`]="{ item }">
                    <span>
                        {{ formatDinheiroString(item.valor_ate) }}
                    </span>
                </template>
                <template v-slot:[`item.data_inicio`]="{ item }">
                    <span>
                        {{ formatDate(item.data_inicio) }}
                    </span>
                </template>
                <template v-slot:[`item.data_fim`]="{ item }">
                    <span>
                        {{ formatDate(item.data_fim) }}
                    </span>
                </template>
                <template v-slot:[`item.desconto_porcentagem`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.desconto_porcentagem) }}
                    </span>
                </template>
                <template v-slot:[`item.desconto`]="{ item }">
                    <span>
                        {{ formatDinheiroString(item.desconto) }}
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        :color="item.status != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.status"
                        inset
                        :style="{
                            color: item.status != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.cartao`]="{ item }">
                    <v-icon
                        :style="{ color: item.cartao != '' ? 'green' : 'red' }"
                        >{{ getBolean(item.cartao) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.juros_cartao`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.juros_cartao) }}
                    </span>
                </template>
                <template v-slot:[`item.boleto`]="{ item }">
                    <v-switch
                        :color="item.boleto == true ? 'green' : 'red'"
                        readonly
                        v-model="item.boleto"
                        inset
                        :style="{
                            color: item.boleto == true ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.juros_boleto`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.juros_boleto) }}
                    </span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        small
                        elevation="1"
                        width="100%"
                        :loading="loadingFiltros"
                        @click="exibirDetalhe(item)"
                        class="mr-2 grey white--text"
                    >
                        <v-icon
                            small
                            class="mr-2"
                            @click="exibirDetalhe(item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >
                        Ver Título
                    </v-btn>
                </template>
                <template v-slot:[`item.action_desabilitar`]="{ item }">
                    <v-btn
                        small
                        elevation="1"
                        width="100%"
                        v-if="item.status == true"
                        :loading="loadingFiltros"
                        @click="botaodesabilitarOferta(item)"
                        class="mr-2 grey white--text"
                    >
                        <v-icon
                            small
                            class="mr-2"
                            @click="botaodesabilitarOferta(item)"
                            :style="{ color: 'white' }"
                            >close</v-icon
                        >
                        Desabilitar
                    </v-btn>
                    <v-btn
                        small
                        elevation="1"
                        width="100%"
                        disabled
                        v-if="item.status == false"
                        :loading="loadingFiltros"
                        class="mr-2 grey white--text"
                    >
                        <v-icon small class="mr-2" :style="{ color: 'white' }"
                            >mdi-information</v-icon
                        >
                        Desabilitado
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <v-dialog
            v-if="dialogTitulo"
            v-model="dialogTitulo"
            max-width="1200px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre a oferta -->
            <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submit">
                    <form ref="ofertaForm">
                        <v-card class="ml-1">
                            <v-toolbar flat dark color="primary">
                                <v-btn icon dark @click="closeDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>

                                <v-col cols="12" sm="2" align="left">
                                    <v-toolbar-title
                                        >Detalhes Titulo</v-toolbar-title
                                    >
                                </v-col>
                            </v-toolbar>
                            <v-card-text>
                                <DetalheTitulo
                                    :titulo="titulo"
                                    :setAtualizarTitulo="setAtualizarTitulo"
                                    :mostrarConfirmacao="mostrarConfirmacao"
                                    :habilitaCorrecao="habilitaCorrecao"
                                    @fecharModal="closeDialog"
                                    @showSnackbar="snackbarDetalheTitulo"
                                    @atualizarTitulo="atualizarTitulo"
                                />
                            </v-card-text>
                            <v-container>
                                <v-row
                                    class="pa-2"
                                    align="baseline"
                                    justify="end"
                                >
                                    <v-btn
                                        class="ml-5"
                                        v-if="oferta.id"
                                        dense
                                        color="grey white--text"
                                        @click="closeDialog"
                                    >
                                        Fechar
                                    </v-btn>
                                    <v-btn
                                        v-if="!oferta.id"
                                        class="ml-5"
                                        dense
                                        color="error"
                                        @click="closeDialog"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        v-if="!oferta.id"
                                        class="ml-5"
                                        color="green white--text"
                                        :disabled="invalid"
                                        @click="salvarOferta"
                                    >
                                        Salvar
                                    </v-btn>
                                    <v-icon class="ml-2"></v-icon>
                                </v-row>
                            </v-container>
                        </v-card>
                    </form>
                </form>
            </validation-observer>
            <!-- fim das informações sobre o titulo--------------------- -->
        </v-dialog>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="dialogLoading"
            width="450px"
        >
            <div class="text-center" width="420px">
                <v-card>
                    <v-progress-circular
                        class="ma-12"
                        indeterminate
                        size="150"
                        color="primary"
                    >
                        {{ mensagemLoading }}
                    </v-progress-circular>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import * as Formatter from '@/helpers/Formatter';
import OfertaService from '@/services/OfertaService';
import TituloService from '@/services/TituloService';
import Oferta from '@/domains/Oferta';
import DetalheTitulo from '@/components/DetalheTitulo';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'OfertasTitulo',
    mixins: [validationMixin],

    components: {
        DetalheTitulo,
        ValidationObserver
    },

    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    data() {
        return {
            dialogLoading: false,
            mensagemLoading: null,
            mostrarConfirmacao: false,
            setAtualizarTitulo: false,
            habilitaCorrecao: false,
            dialogTitulo: false,
            activeIdOffer: false,
            snackbar: false,
            snackbarColor: 'green white--text',
            mensagem: null,
            ofertas: [],
            dialogInicio: false,
            filtros: {
                status: null,
                id_oferta: null,
                numero_titulo: null
            },
            dialog: false,
            loadingFiltros: null,
            data_inicio: null,
            data_fim: null,
            ready: false,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: null,
            pagina: 1,
            totalOfertas: 0,
            showPagination: false,
            oferta: new Oferta(),
            mensagemAviso: null,
            headers: [
                {
                    text: 'Id Oferta',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Título',
                    sortable: false,
                    value: 'nosso_numero'
                },
                {
                    text: 'Id do Título',
                    sortable: false,
                    value: 'id_titulo'
                },
                {
                    text: 'Desconto',
                    sortable: false,
                    value: 'desconto'
                },
                {
                    text: 'Desconto Porcentagem',
                    sortable: false,
                    value: 'desconto_porcentagem'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'status'
                },
                {
                    text: 'Boleto',
                    sortable: false,
                    value: 'boleto'
                },
                {
                    text: 'Data de Início',
                    sortable: false,
                    value: 'data_inicio',
                    dataType: 'Date'
                },
                {
                    text: 'Data de Finalização',
                    sortable: false,
                    value: 'data_fim',
                    dataType: 'Date'
                },
                { text: ' ', sortable: false, value: 'action' },
                { text: ' ', sortable: false, value: 'action_desabilitar' }
            ],
            titulo: null
        };
    },

    computed: {
        computedDateFormattedInicio: {
            get: function() {
                if (this.filtros.data_inicio) {
                    return this.formatDate(this.filtros.data_inicio);
                } else {
                    return this.formatDate(this.data_inicio);
                }
            },
            set: function(value) {
                this.filtros.data_inicio = value;
            }
        },
        computedDateFormattedFim: {
            get: function() {
                if (this.filtros.data_fim) {
                    return this.formatDate(this.filtros.data_fim);
                } else {
                    return this.formatDate(this.data_fim);
                }
            },
            set: function(value) {
                this.filtros.data_fim = value;
            }
        }
    },

    methods: {
        botaodesabilitarOferta: function(oferta) {
            this.dialogLoading = true;
            this.mensagemLoading = `Desabilitando Oferta: ${oferta.id}`;
            let p_oferta = oferta.id;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService.desabilitarOfertaTitulo(p_oferta).then(
                response => {
                    if (response.status == 200) {
                        this.mensagemAviso = 'Desabilitado com sucesso';
                        this.tipoMensagem = 'success';
                        this.snackbar = true;
                        this.dialogLoading = false;
                        this.botaoFiltrar();
                    }
                },
                error => {
                    console.log(error);
                    this.mensagemAviso = 'Erro ao desabilitar Oferta';
                    this.snackbar = true;
                    this.snackbarColor = 'red white--text';
                }
            );
        },
        closeDialogSuccess() {
            this.dialog = false;
            this.mensagemAviso = 'Oferta atualizada com Sucesso';
            this.snackbarColor = 'success';
            this.snackbar = true;
            this.activeIdOffer = false;
            this.botaoFiltrar();
        },
        closeDialogError() {
            this.dialog = false;
            this.mensagemAviso = 'Erro ao atualizar Oferta';
            this.snackbarColor = 'error';
            this.snackbar = true;
            this.activeIdOffer = false;
            this.botaoFiltrar();
        },
        closeDialog() {
            this.dialog = false;
            this.dialogTitulo = false;
            this.oferta = null;
            this.oferta = [];
            this.activeIdOffer = false;
            this.botaoFiltrar();
        },
        dialogInicioOpen() {
            this.dialogInicio = true;
        },
        closeDialogInicio() {
            this.dialogInicio = false;
        },
        botaoFiltrar() {
            if (this.pagina == 1) {
                this.aplicarFiltros();
            } else {
                this.pagina = 1;
            }
        },
        limparDataInicio() {
            this.oferta.data_inicio = [];
        },
        limparDataFim() {
            this.oferta.data_fim = [];
        },
        formatDate(date) {
            if (!date) return null;
            if (date.includes('-')) {
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        },
        aplicarFiltros() {
            this.ultimoFiltro = {};
            if (this.filtros.id_oferta) {
                this.ultimoFiltro.id_oferta = this.filtros.id_oferta;
            }
            if (this.filtros.numero_titulo) {
                this.ultimoFiltro.numero_titulo = this.filtros.numero_titulo;
            }
            if (this.filtros.status) {
                this.ultimoFiltro.status = this.filtros.status;
            }
            this.buscarOfertas();
        },
        buscarOfertas() {
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                let params = this.ultimoFiltro;
                try {
                    let service = new OfertaService(Vue.http, this.$store);

                    service.buscarOfertaTitulo(params).then(
                        response => {
                            this.ofertas = [];
                            this.ofertas = response.data;
                            this.loadingFiltros = false;
                            this.loading = false;
                        },
                        error => {
                            this.loadingFiltros = false;
                            this.loading = false;
                            this.mensagem = error;
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        formatDinheiroString(valor) {
            if (valor == null) {
                return 'Não selecionado';
            } else {
                return 'R$ ' + Formatter.toString(valor);
            }
        },
        formatPorcentagem(valor) {
            if (valor == null) {
                return 'Não selecionado';
            } else {
                return Formatter.toString(valor) + '%';
            }
        },
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        exibirDetalhe(oferta) {
            this.dialogLoading = true;
            this.mensagemLoading = 'Carregando dados do Título';
            this.loadingFiltros = true;
            this.oferta = new Oferta();
            this.activeIdOffer = true;
            let ofertaSelecionada = oferta.id;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            let tituloService = new TituloService(Vue.http, this.$store);
            let titulo = oferta;
            ofertaService.exibirDetalheOfertaTitulo(ofertaSelecionada).then(
                response => {
                    this.oferta = response.body[0];
                    this.dialog = true;
                    this.loadingFiltros = false;
                    this.dialogLoading = false;
                    tituloService.detalharTitulo(titulo).then(response => {
                        this.titulo = response.body.detalhe_titulo;
                        console.log(response);
                        console.log(this.titulo);
                        this.dialogTitulo = true;
                    });
                },
                error => {
                    this.mensagemAviso = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        criarOferta() {
            this.oferta = new Oferta();
            this.activeIdOffer = false;
            this.dialog = true;
            this.dialogLoading = false;
        },
        salvarOferta: function() {
            console.log('salvarOferta');
            //TODO: fazer com que o limite de parcelas no boleto seja 1x
        }
    },
    mounted() {}
};
</script>
